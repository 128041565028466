// footer.scss

.footer {

  // z-index: 50;
  position: relative;
  margin-top: 80px;
  width: 100%;
  height: 345px;
  // padding: 0 15%;
  box-sizing: border-box;

  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  background: url(../../assets/images/footer_bg.png) no-repeat 50% 50%;
  background-size: cover;

  >.footer_inner {
    width: 100%;
    height: 125px;
    border-top: 1px solid rgba(110, 70, 199, 0.5);
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding-top: 32px;
    justify-content: space-between;

    >.footer_box1 {
      margin-left: 15%;
      display: flex;
      flex-flow: column wrap;

      >.footer_box1_inner {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 20px;

        >a {
          font-size: 15px;
          font-weight: 400;
          color: #757A88;

          &:nth-of-type(1) {
            position: relative;
            padding-right: 20px;
            margin-right: 20px;

            &::after {
              position: absolute;
              top: 0;
              right: 0;
              display: block;
              content: "";
              width: 1px;
              height: 100%;
              background: #757A88;
            }
          }

          &:hover {
            color: #FFF;
          }
        }
      }

      >.copyright {
        font-size: 13px;
        color: #757A88;
      }
    }

    >.footer_box2 {
      margin-right: 15%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      >.sns_box {
        display: flex;
        flex-flow: row wrap;
        margin-right: 30px;

        >div {
          width: 24px;
          height: 24px;
          margin-right: 20px;
          cursor: pointer;
        }

        >.medium {
          background: url(../../assets/images/medium.png) no-repeat 50% 50%;
          background-size: contain;

          &:hover {
            background: url(../../assets/images/medium_w.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        >.facebook {
          background: url(../../assets/images/facebook.png) no-repeat 50% 50%;
          background-size: contain;

          &:hover {
            background: url(../../assets/images/facebook_w.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        >.telegram {
          background: url(../../assets/images/telegram.png) no-repeat 50% 50%;
          background-size: contain;

          &:hover {
            background: url(../../assets/images/telegram_w.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        >.twitter {
          background: url(../../assets/images/twitter.png) no-repeat 50% 50%;
          background-size: contain;
          

          &:hover {
            background: url(../../assets/images/twitter_w.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        >.discord {
          background: url(../../assets/images/discord.png) no-repeat 50% 50%;
          background-size: contain;
          margin-right: 0;

          &:hover {
            background: url(../../assets/images/discord_w.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

      }

      > .language_box {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        >p {
          font-size: 15px;
          font-weight: 400;
          color: #757A88;
          margin-right: 10px;
        }

        >.language_arrow {
          width: 12px;
          height: 7.5px;
          background: url(../../assets/images/language_arrow.png) no-repeat 50% 50%;
          background-size: contain;

          &.on {
            transform: rotate(180deg);
          }
        }

        >.language_box_inner {
          display: none;
          position: absolute;
          top: -95px;
          background: #1C1C3C;
          padding: 15px;
          border-radius: 10px;
          box-sizing: border-box;

          >li {
            margin-bottom: 15px;
            color: #757A88;
            cursor: pointer;

            &:last-of-type {
              margin-bottom: 0;
            }

            &:hover,
            &.on {
              color: #FFF;
            }
          }
        }

        &.on {
          >.language_box_inner {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .footer {
    padding: 0 5%;
    margin-top: 100px;
    background: none;
    border-top: 1px solid rgba(110, 70, 199, 0.5);
    height: 170px;
    flex-flow: inherit;

    >.footer_inner {
      height: 100%;
      border-top: none;
      flex-flow: row wrap;
      padding-top: 30px;

      >.footer_box1,
      >.footer_box2 {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    padding: 0 15px;

    >.footer_inner {
      width: 100%;

      >.footer_box1 {
        >.footer_box1_inner {
          margin-bottom: 0;

          >a {
            &:nth-of-type(1) {
              padding-right: 15px;
              margin-right: 15px;
            }

            &:hover {
              color: #FFF;
            }
          }
        }

        >.copyright {
          position: absolute;
          bottom: 25px;
        }
      }

      >.footer_box2 {
        >.sns_box {
          position: absolute;
          left: 15px;
          bottom: 55px;

          >div {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
  }
}