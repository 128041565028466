//  walletButton

.wallet_button {
  width: 100%;
  padding: 18px 0;
  background: #6E46C7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.disabled {
    background: #D9D9D9;
    cursor: inherit;

    &:hover {
      background: #D9D9D9;
      ;
    }
  }

  > p {
    font-size: 15px;
    font-weight: 400;
    color: #FFF;
  }

  &:hover {
    background: #9F78F8;
  }
}