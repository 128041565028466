// notFound.scss

.App {
  > .not_found_container {
    width: 100%;
    height: 100vh;
    background: url(../../assets/images/error/error_bg.png) no-repeat 50% 50%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    > .content1 {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 200px;
      > .logo {
        width: 175px;
        height: 62px;
        background: url(../../assets/images/logo.png) no-repeat 50% 50%;
        background-size: contain;
        margin-bottom: 60px;
      }
      > .title {
        font-style: italic;
        font-weight: 700;
        font-size: 48px;
        color: #FFFFFF;
        margin-bottom: 25px;
      }
      > .description {
        width: 500px;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #767676;
        margin-bottom: 25px;
      }
      > .buttons {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        > div,
        > a {
          display: block;
          padding: 12px 32px;
          border-radius: 33px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: 16px;
          cursor: pointer;
          > .button_arrow {
            width: 8px;
            height: 12px;
            margin-left: 10px;
            background: url(../../assets/images/error/button_arrow.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        > .button_white {
          margin-right: 20px;
          border: 1px solid #FFFFFF;
        }
        > .button_pupple {
          background: #6E46C7;
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 1120px) {
  .App {
    > .not_found_container {
      background: #07071C;
      > .content1 {
        margin-bottom: 0;
        > .logo {
          width: 135px;
          height: 48px;
          margin-bottom: 35px;
        }
        > .title {
          font-size: 36px;
        }
        > .description {
          width: 450px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

@media (max-width: 690px) {
  .App {
    > .not_found_container {
      > .content1 {
        margin-bottom: 0;
        > .logo {
          width: 90px;
          height: 32px;
          margin-bottom: 25px;
        }
        > .title {
          font-size: 24px;
        }
        > .description {
          width: 280px;
          font-size: 14px;
          line-height: 21px;
        }
        > .buttons {
          flex-flow: column nowrap;
          > div,
          > a {
            width: 205px;
            display: block;
            padding: 12px 26px;
            border-radius: 33px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            box-sizing: border-box;
            cursor: pointer;
            > .button_arrow {
              width: 8px;
              height: 12px;
              margin-left: 8px;
              background: url(../../assets/images/error/button_arrow.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          > .button_white {
            padding: 12px 12px 12px 15px;
            margin-right: 0;
            margin-bottom: 15px;
          }
          > .button_pupple {
            background: #6E46C7;
            font-weight: 400;
            font-size: 15px;
          }
        }
      }
    }
  }
}