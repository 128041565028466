// community.scss

@mixin transition($x...){
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
    transition: $x;
}

.community_container {
    width: 100%;

    > .content1 {
        width: 100%;
        height: 370px;
        background: url(../../assets/images/community/content1_bg.png) no-repeat 50% 100%;
        background-size: cover;
        margin-bottom: 30px;
        @include transition(height 2s);
    }

    > .content2 {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        margin-bottom: 200px;

        > .title {
            width: 100%;
            text-align: center;
            font-size: 36px;
            font-weight: 700;
            font-style: italic;
        }
        > .content2_inner {
            width: 1170px;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            margin: 30px 0 55px 0;
            @include transition(width 2s);
            > .content2_box {
                width: 370px;
                cursor: pointer;
                > .image {
                    width: 100%;
                    height: 202px;
                    margin-bottom: 20px;
                }
                > .title {
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 8px;
                }
                > .date {
                    font-size: 12px;
                    font-weight: 400;
                    color: #767676;
                }
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    margin-bottom: 40px;
                }
                &:nth-of-type(1),
                &:nth-of-type(5),
                &:nth-of-type(6) {
                    > .image {
                        background: url(../../assets/images/community/content2_img1.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
                &:nth-of-type(2),
                &:nth-of-type(4) {
                    > .image {
                        background: url(../../assets/images/community/content2_img2.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
                &:nth-of-type(3) {
                    > .image {
                        background: url(../../assets/images/community/content2_img3.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
            }
        }
        > .normal_button {
            position: inherit;
            background: none;
            border: 1px solid #FFF;
            padding: 12px 24px;
            border-radius: 12px;
            > p {
                font-size: 16px;
                font-weight: 700;
                margin-right: 14px;
            }
        }
    }
    
    > .content3 {
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        > .title {
            width: 100%;
            font-size: 36px;
            font-weight: 700;
            font-style: italic;
            text-align: center;
            margin-bottom: 25px;
        }
        > .content3_inner {
            width: 1170px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            @include transition(width 2s);
            > .content3_box {
                width: 270px;
                background: linear-gradient(180deg, rgba(209, 170, 255, 0.16) 0%, rgba(7, 7, 28, 0.16) 100%);
                border: 1px solid rgba(99, 86, 163, 0.6);
                border-radius: 12px;
                padding: 29px 18px;
                box-sizing: border-box;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                cursor: pointer;
                > .image {
                    width: 62px;
                    height: 62px;
                    border-radius: 50%;
                    margin-right: 15px;
                }
                > .content3_box_inner {
                    > .channel {
                        font-size: 16px;
                        font-weight: 700;
                        color: #6E46C7;
                        margin-bottom: 10px;
                    }
                    > .hash {
                        font-size: 13px;
                        font-weight: 400;
                        color: #767676;
                    }
                }
                &:nth-of-type(1) {
                    > .image {
                        background: url(../../assets/images/community/content3_logo1.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
                &:nth-of-type(2) {
                    > .image {
                        background: url(../../assets/images/community/content3_logo2.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
                &:nth-of-type(3) {
                    > .image {
                        background: url(../../assets/images/community/content3_logo3.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
                &:nth-of-type(4) {
                    > .image {
                        background: url(../../assets/images/community/content3_logo4.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
            }
        }
    }
}

@media (max-width: 1170px) {
    .community_container {
    
        > .content1 {
            height: 217px;
            margin-bottom: 65px;
        }
    
        > .content2 {
            margin-bottom: 160px;
            > .content2_inner {
                width: 690px;
                margin: 25px 0 48px 0;
                > .content2_box {
                    width: 310px;
                    > .image {
                        height: 160px;
                    }
                    &:nth-of-type(1),
                    &:nth-of-type(5),
                    &:nth-of-type(6) {
                        > .image {
                            background: url(../../assets/images/community/content2_img1_m.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(2),
                    &:nth-of-type(4) {
                        > .image {
                            background: url(../../assets/images/community/content2_img2_m.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(3) {
                        > .image {
                            background: url(../../assets/images/community/content2_img3_m.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                }
            }
        }
        
        > .content3 {
            > .title {
                margin-bottom: 25px;
            }
            > .content3_inner {
                width: 690px;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                > .content3_box {
                    width: 330px;
                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

@media (max-width: 690px) {
    .community_container {
    
        > .content1 {
            height: 153px;
            margin-bottom: 10px;
        }
    
        > .content2 {
            margin-bottom: 160px;
            > .title {
                font-size: 26px;
                margin-bottom: 24px;
            }
            > .content2_inner {
                width: 310px;
                align-items: center;
                flex-flow: column wrap;
                margin: 25px 0 50px 0;
                > .content2_box {
                    width: 310px;
                    > .image {
                        height: 174px;
                    }
                    &:nth-of-type(1),
                    &:nth-of-type(5),
                    &:nth-of-type(6) {
                        > .image {
                            background: url(../../assets/images/community/content2_img1_tablet.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(2),
                    &:nth-of-type(4) {
                        > .image {
                            background: url(../../assets/images/community/content2_img2_tablet.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(3) {
                        > .image {
                            background: url(../../assets/images/community/content2_img3_tablet.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                }
            }
        }
        
        > .content3 {
            > .title {
                margin-bottom: 25px;
            }
            > .content3_inner {
                width: 310px;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                > .content3_box {
                    width: 140px;
                    flex-flow: column wrap;
                    align-items: flex-start;
                    padding: 29px 16px;
                    box-sizing: border-box;
                    > .image {
                        margin-bottom: 15px;
                    }
                    > .content3_box_inner {
                        > .hash {
                            font-size: 11px;
                        }
                    }
                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        margin-bottom: 30px;
                    }
                    &:nth-of-type(4) {
                        > .content3_box_inner {
                            > .hash {
                                letter-spacing: -0.1px;
                            }
                        }
                    }
                }
            }
        }
    }
}