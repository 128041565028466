// exaFoundation.scss

@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}


.exa_foundation_container {
  width: 100%;

  .content1 {
    width: 100%;
    height: 950px;
    background: url(../../assets/images/exa_foundation/content1_bg.png) no-repeat 50% 100%;
    background-size: cover;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 55px;
    @include transition(height 2s);

    >.title {
      margin-top: 240px;
      width: 100%;
      font-size: 48px;
      font-weight: 700;
      font-style: italic;
      text-align: center;
      margin-bottom: 20px;
      @include transition(font-size 2s);
    }

    >.description,
    >.description2,
    >.description3 {
      width: 1000px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      text-align: center;
      white-space: normal;
      @include transition(font-size, width 2s);
    }

    >.description2 {
      margin-bottom: 40px;
    }
  }

  .content2 {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 200px;

    >.content2_box {
      width: 370px;
      margin-right: 30px;

      >.image {
        width: 370px;
        height: 284px;
        @include transition(width, height 2s);
      }

      >.description {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        white-space: normal;
      }

      &:nth-of-type(1) {
        >.image {
          background: url(../../assets/images/exa_foundation/content2_img1.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }

      &:nth-of-type(2) {
        >.image {
          background: url(../../assets/images/exa_foundation/content2_img2.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }

      &:nth-of-type(3) {
        margin-right: 0;

        >.image {
          background: url(../../assets/images/exa_foundation/content2_img3.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
    }
  }

  .content3 {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    >.title {
      width: 100%;
      font-size: 48px;
      font-weight: 700;
      font-style: italic;
      text-align: center;
      margin-bottom: 50px;
    }

    >.content3_inner {
      width: 1170px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      @include transition(width 2s);

      >.content3_inner_box {
        width: 260px;
        margin: 0 65px 70px 65px;

        >.img {
          position: relative;
          width: 100%;
          height: 300px;
          border-radius: 36px;
          margin-bottom: 15px;
          overflow: hidden;
          cursor: pointer;
          @include transition(height 2s);

          >.img_inner {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            background: #6E46C7;
            opacity: 0.8;
            border-radius: 36px;
            padding: 35px;
            box-sizing: border-box;
            @include transition(top 1s);

            >p {
              width: 100%;
              white-space: normal;
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
            }
          }

          &:hover {
            >.img_inner {
              top: 0;
            }
          }
        }

        >.name {
          width: 100%;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 10px;
        }

        >.roll {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          color: #767676;
          text-align: center;
        }

        &:nth-of-type(1) {
          >.img {
            background: url(../../assets/images/exa_foundation/content3_img1.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        &:nth-of-type(2) {
          >.img {
            background: url(../../assets/images/exa_foundation/content3_img2.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        &:nth-of-type(3) {
          >.img {
            background: url(../../assets/images/exa_foundation/content3_img3.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        &:nth-of-type(4) {
          >.img {
            background: url(../../assets/images/exa_foundation/content3_img4.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        &:nth-of-type(5) {
          >.img {
            background: url(../../assets/images/exa_foundation/content3_img5.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }

        &:nth-of-type(6) {
          >.img {
            background: url(../../assets/images/exa_foundation/content3_img6.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }
    }
  }
}


@media (max-width: 1170px) {
  .exa_foundation_container {
    .content1 {
      height: 665px;
      margin-bottom: 50px;

      >.title {
        margin-top: 140px;
        font-size: 36px;
      }

      >.description,
      >.description2,
      >.description3 {
        width: 540px;
        font-size: 16px;
        line-height: 24px;
      }

      >.description2 {
        margin-bottom: 30px;
      }
    }

    .content2 {
      margin-bottom: 160px;

      >.content2_box {
        width: 210px;
        margin-right: 30px;

        >.image {
          width: 210px;
          height: 160px;
        }

        >.description {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .content3 {
      >.title {
        font-size: 36px;
        margin-bottom: 35px;
      }

      >.content3_inner {
        width: 690px;

        >.content3_inner_box {
          width: 210px;
          margin: 0 0 65px 0;

          >.img {
            height: 249px;
            margin-bottom: 15px;

            >.img_inner {
              padding: 25px;
              box-sizing: border-box;
            }
          }

          &:nth-of-type(1) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img1_tablet.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          &:nth-of-type(2) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img2_tablet.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          &:nth-of-type(3) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img3_tablet.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          &:nth-of-type(4) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img4_tablet.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          &:nth-of-type(5) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img5_tablet.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          &:nth-of-type(6) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img6_tablet.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 690px) {
  .exa_foundation_container {
    .content1 {
      margin-bottom: 10px;

      >.title {
        font-size: 26px;
        line-height: 31px;
        white-space: normal;
      }

      >.description,
      >.description2,
      >.description3 {
        width: 310px;
        font-size: 13px;
        line-height: 19.5px;
      }

      >.description2 {
        margin-bottom: 25px;
      }
    }

    .content2 {
      margin-bottom: 120px;
      flex-flow: column wrap;
      align-items: center;

      >.content2_box {
        width: 310px;
        margin-right: 0;
        margin-bottom: 65px;

        &:last-of-type {
          margin-bottom: 0;
        }

        >.image {
          width: 310px;
          height: 237px;
        }

        >.description {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .content3 {
      >.title {
        font-size: 32px;
        margin-bottom: 35px;
      }

      >.content3_inner {
        width: 310px;

        >.content3_inner_box {
          width: 140px;

          >.img {
            height: 143px;

            >.img_inner {
              padding: 15px;

              >p {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }

          &:nth-of-type(1) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img1_m.png) no-repeat 50% 50%;
              background-size: cover;
            }
          }

          &:nth-of-type(2) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img2_m.png) no-repeat 50% 50%;
              background-size: cover;
            }
          }

          &:nth-of-type(3) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img3_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          &:nth-of-type(4) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img4_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          &:nth-of-type(5) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img5_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }

          &:nth-of-type(6) {
            >.img {
              background: url(../../assets/images/exa_foundation/content3_img6_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}