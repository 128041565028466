.login-header {
  width: 100%;
  // height: 419px;
  margin-top: 200px;
  margin-bottom: 14px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  > .login-title {
    font-size: 30px;
    font-weight: 700;
    color: #1C1B1F;
  }
  > .login-descript {
    font-size: 16px;
    font-weight: 400;
    margin-top: 12px;
    color: #1C1B1F;
    text-align: center;
    line-height: 19px;
  }
}