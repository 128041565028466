// walletConnected.scss

.wallet_box {
  
  // for walletTerms page
  &.connected {
    justify-content: flex-start;
    > .conneted_box {
      width: 100%;
      padding: 0 32px;
      > .service_box {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #D9D9D9;
        padding: 20px 0;
        box-sizing: border-box;
        > .title {
          font-weight: 500;
          font-size: 15px;
          color: #152536;
        }
        > .button {
          padding: 4px 12px;
          border-radius: 11px;
          background: #152536;
          cursor: pointer;
          > .button_title {
            font-weight: 500;
            font-size: 10px;
            color: #FFFFFF;
          }
        }
      }
      p {
        color: #000;
      }
    }
  }

}