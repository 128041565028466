.login-button {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  > .wallet-login-button {
    width: 100%;
    margin-top: 10px;
    padding: 18px 0;
    border-radius: 5px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    > .icon {
      position: absolute;
      left: 32px;
      width: 16px;
      height: 16px;
    }
    > p {
      font-size: 15px;
      font-weight: 400;
      color: #FFF;
    }
    &.disabled {
      background: #D9D9D9;
      cursor: inherit;

      &:hover {
        background: #D9D9D9;
        ;
      }
    }
  }

  & .google {
    background: #EA4335;
    > .icon {
      background: url(../../assets/images/wallet/wallet_login_facebook_icon.png) no-repeat;
      background-size: 16px;
    }
    &:hover {
      background: #FF7266;
      border-radius: 5px;
    }
  }
  & .apple {
    background: #000000;
    > .icon {
      background: url(../../assets/images/wallet/wallet_login_apple_icon.png) no-repeat;
      background-size: 16px;
    }
    &:hover {
      background: #6C6C6C;
      border-radius: 5px;
    }
  }
  & .facebook {
    background: #2374F2;
    > .icon {
      background: url(../../assets/images/wallet/wallet_login_facebook_icon.png) no-repeat;
      background-size: 16px;
    }
    &:hover {
      background: #5B9BFF;
      border-radius: 5px;
    }
  }
}