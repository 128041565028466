// walletMain.scss

.wallet_box {
  &.main {
    justify-content: flex-start;

    > .main_container {
      height: 607px;
      padding: 24px 32px 0 32px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
      > .content1 {
        width: 100%;
        margin-bottom: 32px;
        > .content1_inner {
          width: 100%;
          height: 89px;
          &:nth-of-type(1) {
            margin-bottom: 32px;
            > .title {
              width: 100%;
              font-weight: 400;
              font-size: 15px;
              color: #97999E;
              margin-bottom: 10px;
            }
            > .content1_inner_box {
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 5px;
              > .total {
                font-weight: 700;
                font-size: 32px;
                color: #6E46C7;
                margin-right: 10px;
              }
              > .token {
                padding: 2px 12px;
                border-radius: 17px;
                box-sizing: border-box;
                background: #6E46C7;
                > p {
                  font-size: 14px;
                  font-weight: 700;
                  color: #FFF;
                }
              }
            }
            > .usd {
              width: 100%;
              font-weight: 500;
              font-size: 15px;
              color: #1C1B1F;
            }
          }
          &:nth-of-type(2) {
            height: 77px;
            display: flex;
            flex-flow: row nowrap;
            > .wallet_main_button {
              flex: 1;
              padding: 17px 17px 10px 17px;
              background: #F4F4F4;
              border-radius: 10px;
              margin-right: 20px;
              // cursor: pointer;
              > .image {
                width: 24px;
                height: 24px;
                margin-bottom: 10px;
              }
              > .title {
                font-weight: 400;
                font-size: 15px;
                color: #000000;
              }
              &:nth-of-type(1) {
                > .image {
                  background: url(../../../assets/images/wallet/wallet_main_button1.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
              &:nth-of-type(2) {
                > .image {
                  background: url(../../../assets/images/wallet/wallet_main_button2.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
              &:last-of-type {
                margin-right: 0;
                > .image {
                  width: 26px;
                  background: url(../../../assets/images/wallet/wallet_main_button3.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
              // &:hover {
              //   background: #F5F3FA;
              //   > .title {
              //     color: #6E46C7;
              //   }
              //   &:nth-of-type(1) {
              //     > .image {
              //       background: url(../../../assets/images/wallet/wallet_main_button1_h.png) no-repeat 50% 50%;
              //       background-size: contain;
              //     }
              //   }
              //   &:nth-of-type(2) {
              //     > .image {
              //       background: url(../../../assets/images/wallet/wallet_main_button2_h.png) no-repeat 50% 50%;
              //       background-size: contain;
              //     }
              //   }
              //   &:last-of-type {
              //     margin-right: 0;
              //     > .image {
              //       width: 26px;
              //       background: url(../../../assets/images/wallet/wallet_main_button3_h.png) no-repeat 50% 50%;
              //       background-size: contain;
              //     }
              //   }
              // }
            }
          }
        }

      }
      > .content2 {
        width: 100%;
        > .wallet_top_menu {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          > li {
            color: #97999E;
            cursor: pointer;
            display: block;
            margin: 0 15px;
            padding: 10px 0 12px 0;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 15px;
            &:hover,
            &.on {
              color: #6E46C7;
              border-bottom: 3px solid #6E46C7;
            }
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
      > .content2_box {
        width: 100%;
        height: 310px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        overflow-y: scroll;

        &.token {
          overflow-y: auto;
          > .token_list {
            flex: 0 0 auto;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            cursor: pointer;
            &:last-of-type {
              margin-bottom: 20px;
            }
            > .left {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: flex-start;
              > .image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid #D9D9D9;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
                > .image_inner {
                  width: 30px;
                  height: 30px;
                  background: url(../../../assets/images/wallet/token_exa.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
              > .text_box {
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: center;
                > .token {
                  font-weight: 600;
                  font-size: 15px;
                  color: #152536;
                  margin-bottom: 5px;
                }
                > .memo {
                  font-weight: 400;
                  font-size: 13px;
                  color: #97999E;
                }
              }
            }
            > .value {
              font-weight: 700;
              font-size: 15px;
              color: #152536;
            }
          }
        }

        &.nft {
          flex-flow: row wrap;
          align-items: flex-start;
          box-sizing: border-box;
          > .nft_list {
            flex: 1;
            min-width: 143px;
            margin-right: 20px;
            box-sizing: border-box;
            margin-top: 20px;
            cursor: pointer;
            &.video {
              > .image {
                position: relative;
                &::after {
                  display: block;
                  content: "";
                  position: absolute;
                  bottom: 10px;
                  right: 10px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  background: url(../../../assets/images/wallet/nft_play.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
            }
            &:nth-last-of-type(1),
            &:nth-last-of-type(2) {
              margin-bottom: 20px;
            }
            > .image {
              width: 100%;
              height: 143px;
              border-radius: 10px;
              margin-bottom: 10px;
            }
            > p {
              font-weight: 600;
              font-size: 15px;
              line-height: 24px;
              color: #152536;
            }
            &:nth-of-type(2n) {
              margin-right: 0;
            }
          }
        }

        &.history {
          > .history_list {
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            padding: 20px 0;
            border-bottom: 1px solid #D9D9D9;
            &:last-of-type {
              border-bottom: none;
            }
            > div {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              p {
                font-weight: 500;
                font-size: 15px;
                color: #152536;
                &.type {
                  font-weight: 500;
                  font-size: 13px;
                  &.pending {
                    color: #EA4335;
                  }
                  &.complete {
                    color: #2374F2;
                  }
                }
                &.date {
                  font-weight: 400;
                  font-size: 12px;
                }
                span {
                  font-weight: 400;
                  font-size: 15px;
                  color: #97999E;
                  text-decoration: underline;
                  cursor: pointer;
                  margin-left: 15px;
                }
              }
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
        > .no_data {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          > p {
            width: 100%;
            text-align: center;
            font-weight: 600;
            font-size: 15px;
            color: #152536;
          }
        }
      }
    }
  }
}

@media (max-width: 667px) {
  .wallet_box {
    &.main {
      > .main_container {
        position: absolute;
        top: 60px;
      }
    }
  }
}