//  walletMenu.scss

.wallet_box {
  &.menu {
    justify-content: flex-start;
    > .wallet_menu_open {
      display: none;
      width: 100%;
      position: absolute;
      top: 60px;
      left: 0px;
      background: #FFF;
      height: 607px;
      border-radius: 0 0 12px 12px;
      box-sizing: border-box;
      padding-top: 24px;
      cursor: default;
      > .wallet_menu {
        position: relative;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 32px;
        border-bottom: 1px solid rgba(217, 217, 217, 0.5);
        margin-bottom: 12px;
        cursor: pointer;
        > p {
          font-weight: 700;
          font-size: 16px;
          color: #1C1B1F;
        }
        &::after {
          display: block;
          content: '';
          position: absolute;
          top: calc(50% - 12px);
          right: 24px;
          width: 24px;
          height: 24px;
          background: url(../../../assets/images/wallet/wallet_menu_arrow.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
      &.on {
        display: block;
      }
    }
  }
}