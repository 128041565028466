// header.scss

@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}


.header {
    z-index: 40;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px;
    box-sizing: border-box;

    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);

    > a {
        > .logo_box {
            z-index: 50;
            width: 90px;
            height: 30px;
            cursor: pointer;
            background: url(../../assets/images/logo.png) no-repeat 50% 50%;
            background-size: contain;
        }
    }
    
    > .gnb {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        > .gnb_mobile_button {
            display: none;
        }

        > .gnb_box {
            width: 700px;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            
            > li {
                
                height: 100%;
                line-height: 80px;
                padding: 0 16px;
                box-sizing: border-box;
                
                > a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    font-size: 18px;
                    font-weight: 700;
                    color: #FFF;
                    cursor: pointer;
                }
                &:hover,
                &.on {
                    box-shadow: inset 0 -2px 0 #9f78f8;
                    > a {
                        color: #9F78F8;
                    }
                }
            }
        }

        .wallet {
            width: 24px;
            height: 24px;
            background: url(../../assets/images/wallet.png) no-repeat 50% 50%;
            background-size: contain;
            text-indent: -9999px;
            overflow: hidden; 
            cursor: pointer;
            margin-left: 20px;
            &:hover,
            &.on {
                border-bottom: none;
                background: url(../../assets/images/wallet_hover.png) no-repeat 50% 50%;
                background-size: contain;
            }
        }
    }
}

@media (max-width: 1000px) {
    .header {
        height: 72px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);
        &.on {
            background: inherit;
            backdrop-filter: inherit;
        }
        > a {
            > .logo_box {
                width: 50px;
                height: 20px;
            }
        }
        > .gnb {
            flex-flow: row-reverse nowrap;
            height: 100%;

            > .blur_box {
                z-index: 50;
                position: fixed;
                top: 0;
                right: 0;
                width: 50%;
                height: 100%;
                box-sizing: border-box;
                background: #1B1B3D;
                @include transition(width 2s);
                &::before {
                    position: absolute;
                    top: 0;
                    right: 100%;
                    display: block;
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.6);
                }
            }
            
            > .gnb_mobile_button {
                display: block;
                z-index: 55;
                position: relative;
                padding: 11px 3px;
                box-sizing: border-box;
                cursor: pointer;
                > .gnb_mobile_button_bar {
                    position: relative;
                    width: 18px;
                    height: 2px;
                    background: #FFF;
                    @include transition(transform .5s);
                    &::before,
                    &::after {
                        position: absolute;
                        top: -5px;
                        left: 0;
                        width: 18px;
                        height: 2px;
                        display: block;
                        content: "";
                        background: #FFF;
                        @include transition(transform .5s);
                    }
                    &::after {
                        top: 5px;
                    }
                }
                &.on {
                    > .gnb_mobile_button_bar {
                            width: 20px;
                            transform: rotate(45deg);
                            transform-origin: 50% 50%;
                        &::before {
                            width: 20px;
                            top: 0;
                            transform: rotate(90deg);
                            transform-origin: 50% 50%;
                        }
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            
            
            > .gnb_box {
                z-index: 50;
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                width: 50vh;
                height: 100%;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                box-sizing: border-box;
                padding-top: 72px;
                &.on {
                    width: 50%;
                    height: inherit;
                    display: block;
                    @include transition(width 2s);
                }
                
                > li {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    border-top: 1px solid rgba(110, 70, 199, 0.5);
                    padding-left: 15px;
                    margin: 0;
                    box-sizing: border-box;
                    &:last-of-type {
                        border-bottom: 1px solid rgba(110, 70, 199, 0.5);
                    }
                    > a {
                        width: 100%;
                        height: 100%;
                        line-height: 60px;
                    }
                }
            }
            .wallet {
                margin-right: 25px;
                margin-left: 0;
            }
        }
    }
}

@media (max-width: 500px) {
    .header {
        height: 72px;
        > a {
            > .logo_box {
                width: 50px;
                height: 20px;
            }
        }
        > .gnb {
            display: flex;
            align-items: center;
            height: 100%;

            > .blur_box {
                width: 100%;
                background: #1B1B3D;
            }
            
            > .gnb_box {
                width: 100% !important;
                &.on {
                    width: 100%;
                }
                
                > li {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    border-top: 1px solid rgba(110, 70, 199, 0.5);
                    padding-left: 15px;
                    margin: 0;
                    box-sizing: border-box;
                    &:last-of-type {
                        border-bottom: 1px solid rgba(110, 70, 199, 0.5);
                    }
                }
            }
        }
    }
}