.dim {
  z-index: 1000 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.7);

  >.modal {
    position: absolute;
    top: 120px;
    left: calc(50% - 180px);
    width: 360px;
    background: #FFF;
    border-radius: 30px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 50px 75px 32px 75px;
  }

  >.alert {
    > .close_button {
      position: absolute;
      top: 16px;
      right: 24px;
      width: 40px;
      height: 40px;
      background: url(../../../assets/images/modal/close_button.png) no-repeat 50% 50%;
      background-size: contain;
      cursor: pointer;
    }
    > .modal_inner {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      > .image {
        width: 208px;
        height: 225px;
        background: url(../../../assets/images/modal/modal.png) no-repeat 50% 50%;
        background-size: contain;
        margin-bottom: 20px;
      }
      > .description {
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
        color: #767676;
        margin-bottom: 32px;
      }
    }
    > .modal_button {
      padding: 12px 32px;
      background: #6E46C7;
      border-radius: 33px;
      cursor: pointer;
      > p {
        color: #FFF;
      }
      &:hover {
        background: #9F78F8;
      }
    }
  }
}

@media (max-width: 690px) {
  .dim {
    z-index: 1000 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.7);
  
    >.modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #FFF;
      border-radius: 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding: 0;
    }
  
    >.alert {
      > .close_button {
        position: absolute;
        top: 16px;
        right: 24px;
        width: 40px;
        height: 40px;
        background: url(../../../assets/images/modal/close_button.png) no-repeat 50% 50%;
        background-size: contain;
        cursor: pointer;
      }
      > .modal_inner {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        > .image {
          width: 208px;
          height: 225px;
          background: url(../../../assets/images/modal/modal.png) no-repeat 50% 50%;
          background-size: contain;
          margin-bottom: 20px;
        }
        > .description {
          width: 100%;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          text-align: center;
          color: #767676;
          margin-bottom: 32px;
        }
      }
      > .modal_button {
        padding: 12px 32px;
        background: #6E46C7;
        border-radius: 33px;
        cursor: pointer;
        > p {
          color: #FFF;
        }
        &:hover {
          background: #9F78F8;
        }
      }
    }
  }
}