//  wallet.scss

.dim {
  z-index: 60;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #07071C, $alpha: 0.7);

  >.x_button {
    position: absolute;
    top: 20px;
    right: 24px;
    width: 40px;
    height: 40px;
    background: url(../../assets/images/wallet/x_button.png) no-repeat 50% 50%;
    background-size: contain;
    cursor: pointer;
  }

  >.wallet_box {
    position: absolute;
    top: 80px;
    right: 24px;
    width: 375px;
    height: 667px;
    border-radius: 12px;
    background: #FFF;
    padding: 24px 32px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    &.wallet_header {
      // position: relative;
      padding: 0;

      >.wallet_header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        background: #FFF;
        padding: 20px 0;
        box-sizing: border-box;
        border-radius: 12px 12px 0 0;

        // backbutton ver.
        >.back_button {
          flex: 1;
          width: 24px;
          height: 24px;
          background: url(../../assets/images/wallet/wallet_header_back.png) no-repeat 50% 50%;
          background-size: contain;
          cursor: pointer;
        }

        >.title {
          flex: 5;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          color: #152536;
        }

        >.empty {
          flex: 1;
        }

        // wallet main ver.
        &.main {
          padding: 0 21px 0 32px;

          >.title {
            font-size: 20px;
            font-weight: 700;
            color: #152536;
            text-align: left;
          }

          >.menu_button {
            width: 40px;
            height: 40px;
            cursor: pointer;
            background: url(../../assets/images/wallet/wallet_header_hamburger.png) no-repeat 50% 50%;
            background-size: contain;

            >.wallet_menu_open {
              flex: 1;
              display: none;
              width: 100%;
              position: absolute;
              top: 60px;
              left: 0px;
              background: #FFF;
              height: 607px;
              border-radius: 0 0 12px 12px;
              box-sizing: border-box;
              padding-top: 24px;
              cursor: default;

              >.wallet_menu {
                position: relative;
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                padding: 0 32px;
                border-bottom: 1px solid rgba(217, 217, 217, 0.5);
                margin-bottom: 12px;
                cursor: pointer;

                >p {
                  font-weight: 700;
                  font-size: 16px;
                  color: #1C1B1F;
                }

                &::after {
                  display: block;
                  content: '';
                  position: absolute;
                  top: calc(50% - 12px);
                  right: 24px;
                  width: 24px;
                  height: 24px;
                  background: url(../../assets/images/wallet/wallet_menu_arrow.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }

            }

            &.open {
              background: url(../../assets/images/wallet/wallet_header_xbutton.png) no-repeat 50% 50%;
              background-size: contain;

              >.wallet_menu_open {
                display: block;
              }
            }

          }
        }
      }
    }

    >.logo {
      width: 100%;
      margin-top: 200px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      >.image {
        width: 78px;
        height: 78px;
        background: url(../../assets/images/wallet/exa.png) no-repeat 50% 50%;
        background-size: contain;
        margin-bottom: 20px;
      }

      >.title {
        font-size: 30px;
        font-weight: 700;
        color: #1C1B1F;
      }
    }
  }
}

@media (max-width: 667px) {
  .dim {
    >.wallet_box {
      right: 0;
      width: 100%;
      height: calc(100% - 80px);
      border-radius: 0;
      background: #FFF;
      padding: 24px 32px;
      box-sizing: border-box;
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: center;

      .wallet-condition-body {
        height: 100% !important;
      }

      .number_pad {
        width: 400px !important;
        height: 500px !important;
      }

      &.wallet_header {
        >.wallet_header {
          >.back_button {
            position: absolute;
            left: 27px;
          }

          >.empty {
            position: absolute;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .dim {
    >.wallet_box {

      .number_pad {
        width: 100% !important;
        height: 380px !important;
      }
    }
  }
}