// main.scss

@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

.main2_container {
  width: 100%;

  >.main_box1 {
    width: 100%;
    height: 100vh;
    background: url(../../assets/images/main/main_bg.png) no-repeat 50% 80%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 250px;
    margin-bottom: 130px;
    box-sizing: border-box;
    @include transition(height 2s);

    >.main_box1_inner {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;

      .title {
        width: 100%;
        font-size: 85px;
        font-weight: 700;
        line-height: 85px;
        font-style: italic;
        color: #FFF;
        text-align: center;
        @include transition(font-size 2s);
      }

      .description {
        width: 100%;
        font-size: 36px;
        font-weight: 400;
        color: #FFF;
        text-align: center;
        line-height: 44px;
        margin: 8px 0 36px 0;
        @include transition(font-size 2s);
      }
    }
  }

  >.main_box2 {
    width: 100%;
    margin-bottom: 120px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    >.main_box2_inner {
      width: 1170px;
      display: flex;
      flex-flow: row-reverse nowrap;
      justify-content: center;
      align-items: center;
      >.content_box {
        flex: 1;
        // width: 570px;
        margin-left: 100px;

        >.title {
          font-size: 48px;
          font-weight: 700;
          line-height: 48px;
          color: #FFF;
          font-style: italic;
          @include transition(font-size 2s);
        }

        >.description {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          color: #767676;
          margin: 25px 0;
          white-space: normal;
          @include transition(font-size 2s);
        }
      }

      >.image {
        flex: 1;
        height: 390px;
      }

      &:nth-of-type(1) {
        > .image {
          background: url(../../assets/images/main/main2_img1.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
      &:nth-of-type(2) {
        flex-flow: row nowrap;
        > .image {
          background: url(../../assets/images/main/main2_img2.png) no-repeat 50% 50%;
          background-size: contain;
        }
        >.content_box {
          margin-left: 0;
          margin-right: 100px;
        }
      }
      &:nth-of-type(3) {
        > .image {
          background: url(../../assets/images/main/main2_img3.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
    }
  }

  >.main_box3 {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;
    >.main_box3_inner {
      width: 1120px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      > .content_box {
        flex: 1;
        margin-right: 30px;
        margin-left: 0;
        > .title {
          font-weight: 700;
          font-size: 48px;
          font-style: italic;
          margin-bottom: 25px;
        }
        > .description {
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: #767676;
        }
        &:nth-of-type(2) {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          margin-right: 0;
          > .content {
            flex: 1;
            height: 365px;
            margin-right: 30px;
            border-radius: 19px;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 0 8px;
            > .title {
              font-weight: 700;
              font-size: 20px;
              margin-bottom: 8px;
              margin-top: 215px;
            }
            > .description {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #767676;
            }
            &:nth-of-type(1) {
              background: #200C36 url(../../assets/images/main/main3_img.png) no-repeat 50% 0;
              background-size: contain;
            }
            &:last-of-type {
              background: #200C36 url(../../assets/images/main/main3_img2.png) no-repeat 50% 0;
              background-size: contain;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  > .main_box4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;
    >.main_box4_inner {
      width: 1120px;
      height: 430px;
      background: url(../../assets/images/main/main4_img.png) no-repeat 50% 50%;
      background-size: contain;
      > .title {
        width: 70%;
        font-weight: 700;
        font-size: 48px;
        font-style: italic;
        color: #FFFFFF;
      }
      > .description {
        width: 70%;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #767676;
      }
    }
  }

  > .main_box5 {
    width: 100%;
    margin-bottom: 200px;
    > .title {
        width: 100%;
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 15px;
        white-space: pre-line;
        font-style: italic;
    }
    > .description {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #767676;
        margin-bottom: 40px;
    }
    > .content5_inner {
        height: 1450px;
        background: url(../../assets/images/exais/content4.png) no-repeat 50% 50%;
        background-size: contain;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        > .content5_dot {
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: #FFF;
            margin-top: 100px;
            &::after {
                position: absolute;
                top: calc(50% - 1px);
                right: 12px;
                display: block;
                content: "";
                width: 135px;
                height: 2px;
                background: #FFF;
                @include transition(width 2s);
            }
            > .content5_dot_box {
                width: 250px;
                position: absolute;
                top: 0;
                right: 155px;
                display: flex;
                flex-flow: column wrap;
                align-items: flex-end;
                @include transition(right 2s);
                > .date {
                    font-style: italic;
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 6px;
                }
                > .description {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    text-align: right;
                    color: #757A88;
                }
            }
            &:nth-of-type(1) {
                margin-top: 370px;
            }
            &:nth-of-type(2n) {
                &::after {
                    left: 12px;
                }
                > .content5_dot_box {
                    top: 0;
                    left: 155px;
                    position: absolute;
                    align-items: flex-start;
                    @include transition(left 2s);
                    > .description {
                        text-align: left;
                    }
                }
            }
        }
    }
  }

  > .main_box6 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 160px;
    > .main_box6_inner {
      width: 1120px;
      > .title {
        font-weight: 700;
        font-size: 48px;
        font-style: italic;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 25px;
      }
      > .description {
        width: 1120px;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #767676;
        margin-bottom: 65px;
      }
      > .main_box6_scroll_box {
        width: 100%;
        > .scroll {
          width: 100%;
          >.scroll_boxes {
            width: 1120px;
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 55px;
            > .box {
              flex: 1;
              margin-right: 30px;
              > .image {
                width: 100%;
                height: 210px;
                background: #eee;
                margin-bottom: 15px;
                border-radius: 30px;
              }
              > .title {
                font-weight: 700;
                font-size: 20px;
                text-align: center;
                margin-bottom: 8px;
              }
              > .description {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #767676;
              }
              &:nth-of-type(1) {
                > .image {
                  background: url(../../assets/images/main/main6_img1.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
              &:nth-of-type(2) {
                > .image {
                  background: url(../../assets/images/main/main6_img2.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
              &:nth-of-type(3) {
                > .image {
                  background: url(../../assets/images/main/main6_img3.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
              &:nth-of-type(4) {
                margin-right: 0;
                > .image {
                  background: url(../../assets/images/main/main6_img4.png) no-repeat 50% 50%;
                  background-size: contain;
                }
              }
            }
          }
          > .scroll_box {
            width: 100%;
            height: 270px;
            background: url(../../assets/images/main/main6_img.png) no-repeat 50% 50%;
            background-size: contain;
            &.ko {
              background: url(../../assets/images/main/main6_img_ko.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }
      }
    }
    
  }

  > .main_box7 {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    >.title {
      width: 100%;
      font-size: 48px;
      font-weight: 700;
      font-style: italic;
      text-align: center;
      margin-bottom: 50px;
    }

    >.content3_inner {
      width: 1170px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      @include transition(width 2s);

      >.content3_inner_box {
        width: 260px;
        margin: 0 65px 70px 65px;

        >.img {
          position: relative;
          width: 100%;
          height: 300px;
          border-radius: 36px;
          margin-bottom: 15px;
          overflow: hidden;
        }

        >.name {
          width: 100%;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 10px;
        }

        >.roll {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          color: #767676;
          text-align: center;
        }

        &:nth-of-type(1) {
          >.img {
            background: url(../../assets/images/main/team1.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(2) {
          >.img {
            background: url(../../assets/images/main/team2.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(3) {
          >.img {
            background: url(../../assets/images/main/team3.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(4) {
          >.img {
            background: url(../../assets/images/main/team4.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(5) {
          >.img {
            background: url(../../assets/images/main/team5.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(6) {
          >.img {
            background: url(../../assets/images/main/team6.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(7) {
          >.img {
            background: url(../../assets/images/main/team7.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(8) {
          display: none;
          >.img {
            background: url(../../assets/images/main/team8.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(9) {
          >.img {
            background: url(../../assets/images/main/team9.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(10) {
          >.img {
            background: url(../../assets/images/main/team10.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }
    }
  }

  &.ko {
    >.main_box2 {
      >.main_box2_inner {
        >.content_box {
          >.title {
            font-size: 40px;
          }
        }
      }
    }
    >.main_box3 {
      >.main_box3_inner {
        > .content_box {
          > .title {
            font-size: 40px;
          }
        }
      }
    }
    >.main_box4 {
      >.main_box4_inner {
        > .title {
          font-size: 40px;
        }
      }
    }
    >.main_box5 {
      >.title {
        font-size: 40px;
      }
    }
    >.main_box6 {
      > .main_box6_inner {
        > .title {
          font-size: 40px;
        }
        > .main_box6_scroll_box {
          > .scroll {
            >.scroll_boxes {
              > .box {
                > .title {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
    >.main_box7 {
      >.title {
        font-size: 40px;
      }
    }
  }

}

.arrow_button {
  padding: 16px 32px;
  border-radius: 100px;
  border: 2px solid #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;

  >p {
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    color: #FFF;
    margin-right: 15px;
    margin-top: 2px;
  }

  >.button_arrow {
    width: 12px;
    height: 20px;
    background: url(../../assets/images/button_img_w.png) no-repeat 50% 50%;
    background-size: contain;
  }

  &:hover {
    background: #FFF;

    >p {
      color: #000;
    }

    >.button_arrow {
      background: url(../../assets/images/button_img_b.png) no-repeat 50% 50%;
      background-size: contain;
    }
  }
}

@media (max-width: 1120px) {
  .main2_container {
  
    >.main_box1 {
      background: url(../../assets/images/main/main_bg_t.png) no-repeat 50% 80%;
      background-size: cover;
      height: 715px;
      margin-bottom: 20px;
      padding-bottom: 150px;
  
      >.main_box1_inner {
  
        .title {
          font-size: 56px;
          line-height: 56px;
        }

        .description {
          font-size: 28px;
          line-height: 33px;
          margin: 5px 0 20px 0;
        }
  
      }
    }
  
    >.main_box2 {
      margin-bottom: 160px;
      justify-content: flex-start;
  
      >.main_box2_inner {
        width: 690px;
        margin-bottom: 80px;
        >.content_box {
          margin-left: 30px;
          >.title {
            font-size: 36px;
            line-height: 36px;
          }
          >.description {
            font-size: 16px;
            line-height: 25px;
            margin: 15px 0 0 0;
          }
        }
  
        >.image {
          height: 230px;
        }
      }
    }
  
    >.main_box3 {
      >.main_box3_inner {
        width: 690px;
        flex-flow: column nowrap;
        > .content_box {
          > .title {
            width: 100%;
            text-align: center;
            font-size: 36px;
          }
          > .description {
            width: 100%;
            text-align: center;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 65px;
          }
          &:nth-of-type(2) {
            > .content {
              padding: 0 38px;
              > .title {
                margin-top: 225px;
              }
            }
          }
        }
      }
    }
  
    > .main_box4 {
      >.main_box4_inner {
        width: 690px;
        background: url(../../assets/images/main/main4_img_t.png) no-repeat 50% 50%;
        background-size: contain;
        > .title {
          width: 65%;
          font-size: 36px;
          margin-bottom: 25px;
        }
        > .description {
          width: 65%;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  
    > .main_box5 {
      > .content5_inner {
          > .content5_dot {
              &::after {
                  width: 80px;
              }
              > .content5_dot_box {
                  right: 100px;
              }
              &:nth-of-type(2n) {
                  > .content5_dot_box {
                      left: 100px;
                  }
              }
          }
      }
  }
  
    > .main_box6 {
      > .main_box6_inner {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        > .title {
          width: 690px;
        }
        > .description {
          width: 690px;
        }
        > .main_box6_scroll_box {
          overflow-x: scroll;
          > .scroll {
            width: 1120px;
            margin: 0 39px;
            >.scroll_boxes {
              width: 100%;
            }
          }
        }
      }
      
    }
  
    >.main_box7 {
  
      >.title {
        font-size: 36px;
        margin-bottom: 25px;
      }
  
      >.content3_inner {
        width: 690px;
  
        >.content3_inner_box {
          width: 210px;
          margin: 0 0 65px 0;
        }
      }
    }
  
    &.ko {
      >.main_box2 {
        >.main_box2_inner {
          >.content_box {
            >.title {
              font-size: 28px;
            }
          }
        }
      }
      >.main_box3 {
        >.main_box3_inner {
          > .content_box {
            > .title {
              font-size: 28px;
            }
          }
        }
      }
      >.main_box4 {
        >.main_box4_inner {
          > .title {
            font-size: 28px;
          }
        }
      }
      >.main_box5 {
        padding: 0 39px;
        >.title {
          font-size: 28px;
        }
        > .description {
          box-sizing: border-box;
        }
      }
      >.main_box6 {
        > .main_box6_inner {
          > .title {
            font-size: 28px;
          }
          > .main_box6_scroll_box {
            > .scroll {
              >.scroll_boxes {
                > .box {
                  > .title {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
      >.main_box7 {
        >.title {
          font-size: 28px;
        }
      }
    }
  }

  .arrow_button {
    padding: 10px 25px;
    > p {
        font-size: 16px;
        line-height: 16px;
    }
}
}

@media (max-width: 690px) {
  .main2_container {
  
    >.main_box1 {
      background: url(../../assets/images/main/main_bg_m.png) no-repeat 50% 80%;
      background-size: cover;
      height: 440px;
      margin-bottom: 30px;
      padding-bottom: 120px;
  
      >.main_box1_inner {

        .title {
          font-size: 32px;
          line-height: 32px;
        }

        .description {
          font-size: 16px;
          line-height: 19px;
        }
  
      }
    }
  
    >.main_box2 {
  
      >.main_box2_inner {
        width: 310px;
        flex-flow: column-reverse nowrap;
        margin-bottom: 80px;
        >.content_box {
          flex: inherit;
          width: 100%;
          margin-left: 0;
          >.title {
            font-size: 26px;
            line-height: 31px;
          }
          >.description {
            font-size: 14px;
            line-height: 22px;
            margin: 15px 0 0 0;
          }
        }
  
        >.image {
          flex: inherit;
          width: 100%;
          height: 214px;
        }
        &:nth-of-type(2) {
          flex-flow: column-reverse nowrap;
          >.content_box {
            margin-left: 0;
            margin-right: 0;
          }
        }
        &:nth-of-type(3) {
          margin-bottom: 0;
        }
      }
    }
  
    >.main_box3 {
      margin-bottom: 170px;
      >.main_box3_inner {
        width: 310px;
        > .content_box {
          margin-right: 0;
          > .title {
            font-size: 36px;
            margin-bottom: 25px;
          }
          > .description {
            font-size: 14px;
            line-height: 21px;
          }
          &:nth-of-type(2) {
            flex-flow: column nowrap;
            > .content {
              flex:none;
              height: 365px;
              margin-right: 0;
              padding: 0 20px;
              > .title {
                margin-top: 220px;
              }
              &:nth-of-type(1) {
                margin-bottom: 45px;
              }
            }
          }
        }
      }
    }
  
    > .main_box4 {
      margin-bottom: 120px;
      >.main_box4_inner {
        position: relative;
        width: 310px;
        height: 430px;
        background: url(../../assets/images/main/main4_img_m.png) no-repeat 0 50%;
        background-size: contain;
        > .title {
          position: absolute;
          top: -50px;
          width: 100%;
          font-weight: 700;
          font-size: 32px;
        }
        > .description {
          margin-top: 150px;
          width: 40%;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  
    > .main_box5 {
      margin-bottom: 120px;
      padding: 0 25px;
      > .title {
          font-size: 32px;
      }
      > .description {
          white-space: normal;
      }
      > .content5_inner {
          > .content5_dot {
              &::after {
                  width: 36px;
              }
              > .content5_dot_box {
                  width: 115px;
                  right: 55px;
                  > p {
                      white-space: normal;
                  }
              }
              &:nth-of-type(2n) {
                  > .content5_dot_box {
                      left: 55px;
                  }
              }
          }
      }
  }
  
    > .main_box6 {
      margin-bottom: 120px;
      > .main_box6_inner {
        > .title {
          width: 310px;
          font-size: 32px;
        }
        > .description {
          width: 310px;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 25px;
        }
        > .main_box6_scroll_box {
          > .scroll {
            width: 1120px;
            margin: 0 25px;
          }
        }
      }
      
    }
  
    >.main_box7 {
  
      >.content3_inner {
        width: 310px;
  
        >.content3_inner_box {
          width: 140px;
          margin: 0 0 40px 0;
  
          >.img {
            height: 143px;
          }
  
          >.name {
            margin-bottom: 5px;
          }
  
          >.roll {
            font-size: 16px;
          }
  
          &:nth-of-type(1) {
            >.img {
              background: url(../../assets/images/main/team1_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(2) {
            >.img {
              background: url(../../assets/images/main/team2_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(3) {
            >.img {
              background: url(../../assets/images/main/team3_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(4) {
            >.img {
              background: url(../../assets/images/main/team4_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(5) {
            >.img {
              background: url(../../assets/images/main/team5_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(6) {
            >.img {
              background: url(../../assets/images/main/team6_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(7) {
            >.img {
              background: url(../../assets/images/main/team7_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(8) {
            >.img {
              background: url(../../assets/images/main/team8_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(9) {
            >.img {
              background: url(../../assets/images/main/team9_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
          &:nth-of-type(10) {
            >.img {
              background: url(../../assets/images/main/team10_m.png) no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }
      }
    }

    &.ko {
      >.main_box2 {
        >.main_box2_inner {
          >.content_box {
            >.title {
              font-size: 20px;
            }
          }
        }
      }
      >.main_box3 {
        >.main_box3_inner {
          > .content_box {
            > .title {
              font-size: 20px;
            }
            &:nth-of-type(2) {
              >.content {
                > .description {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      >.main_box4 {
        >.main_box4_inner {
          > .title {
            font-size: 20px;
          }
        }
      }
      >.main_box5 {
        padding: 0 39px;
        >.title {
          font-size: 20px;
        }
        > .description {
          box-sizing: border-box;
          font-size: 14px;
        }
        >.content5_inner {
          > .content5_dot {
            > .content5_dot_box {
              > .description {
                font-size: 14px;
              }
            }
          }
        }
      }
      >.main_box6 {
        > .main_box6_inner {
          > .title {
            font-size: 20px;
          }
        }
      }
      >.main_box7 {
        >.title {
          font-size: 20px;
        }
      }
    }
  
  }
  .arrow_button {
    padding: 10px 25px;
    > p {
        font-size: 16px;
        line-height: 16px;
    }
}
}