//  privacyPolicy.scss

@mixin transition($x...){
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
    transition: $x;
}

.pp_container {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    > .content1 {
        width: 100%;
        height: 370px;
        background: url(../../assets/images/community/content1_bg.png) no-repeat 50% 100%;
        background-size: cover;
        margin-bottom: 40px;
        @include transition(height 2s);
    }

    > .content2 {
        width: 1090px;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        @include transition(width 2s);
        > .title {
            font-size: 48px;
            font-weight: 700;
            font-style: italic;
            margin-bottom: 45px;
            @include transition(font-size 2s);
        }
        > .description,
        .send_mail {
            white-space: pre-wrap;
            font-size: 16px;
            font-weight: 400px;
            line-height: 24px;
            color: #767676;
        }
        span {
            display: block;
            margin: 45px 0;
            width: 100%;
            font-size: 18px;
            font-weight: 700;
        }
        .send_mail {
            text-decoration: underline;
        }
    }
}

@media (max-width: 1170px) {
    .pp_container {
    
        > .content1 {
            height: 217px;
            margin-bottom: 30px;
        }
    
        > .content2 {
            width: 690px;
            > .title {
                font-size: 36px;
                margin-bottom: 30px;
            }
            > .description,
            .send_mail {
                line-height: 21px;
            }
        }
    }
}

@media (max-width: 768px) {
    .pp_container {
    
        > .content1 {
            height: 153px;
            margin-bottom: 10px;
        }
    
        > .content2 {
            width: 310px;
            > .title {
                font-size: 26px;
                margin-bottom: 15px;
            }
            > .description,
            .send_mail {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}