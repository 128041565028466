//  walletAccount.scss

.wallet_box {
  &.account {
    justify-content: flex-start;

    >.account_box {
      width: 100%;
      padding: 0 32px;

      > .account_inner_box {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #D9D9D9;
        cursor: pointer;
        > .title {
          font-weight: 500;
          font-size: 15px;
          color: #152536;
        }
        > .description {
          text-align: right;
          font-weight: 400;
          font-size: 15px;color: #97999E;
          > span {
            display: block;
            font-weight: 400;
            font-size: 12px;
            color: #2374F2;
            margin-top: 10px;
          }
        }
        &:nth-of-type(1) {
          cursor: default;
        }

        &:nth-of-type(2) {
          &::after {
            content: '';
            width: 18px;
            height: 18px;
            background: url(../../../assets/images/wallet/account_arrow_right.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }
    }
  }
}