// exais.scss

@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

$description-color : #767676;

.exais_container {
    width: 100%;
    > .content1 {
        width: 100%;
        height: 700px;
        background: url(../../assets/images/exais/content1_bg.png) no-repeat 50% 100%;
        background-size: cover;
        @include transition(height 2s);
    }
    
    > .container_inner {
        width: 100%;
        position: relative;
        top: -50px;

        > .content2,
        > .content6 {
            width: 100%;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            > div {
                width: 1170px;
                display: flex;
                flex-flow: row-reverse wrap;
                justify-content: space-between;
                margin-bottom: 80px;
                > .image {
                    flex: 1;
                    max-width: 570px;
                    height: 532px;
                    background: url(../../assets/images/exais/content2_img1.png) no-repeat 50% 50%;
                    background-size: contain;
                    @include transition(height 2s);
                }
                > .content_box_inner {
                    flex: 1;
                    max-width: 570px;
                    height: auto;
                    display: flex;
                    flex-flow: column wrap;
                    align-items: flex-start;
                    justify-content: center;
                    > .title {
                        width: 100%;
                        font-size: 48px;
                        font-weight: 700;
                        font-style: italic;
                        white-space: normal;
                        color: #FFF;
                        margin-bottom: 25px;
                        @include transition(font-size 2s);
                    }
                    > .description {
                        width: 100%;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        color: $description-color;
                        white-space: normal;
                        @include transition(font-size 2s);
                    }
                }
                &:nth-of-type(2) {
                    flex-flow: row wrap;
                    > .image {
                        background: url(../../assets/images/exais/content2_img2.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
            }
        }

        > .content3 {
            width: 100%;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            margin-bottom: 250px;
            padding: 0 25px;
            > .title {
                font-style: italic;
                font-size: 36px;
                font-weight: 700;
                margin-bottom: 15px;
            }
            > .description {
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                line-height: 24px;
                margin-bottom: 65px;
                color: $description-color;
            }

            > .content3_box {
                width: 1170px;
                display: flex;
                flex-flow: row wrap;
                > div {
                    flex: 1;
                    display: flex;
                    flex-flow: column wrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 0 65px;
                    > .image {
                        width: 260px;
                        height: 200px;
                        margin-bottom: 15px;
                    }
                    > .title {
                        white-space: normal;
                        display: flex;
                        flex-flow: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 20px;
                        font-weight: 700;
                        margin-bottom: 8px;
                        > span {
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                    > .description {
                        width: 260px;
                        font-size: 16px;
                        line-height: 24px;
                        white-space: normal;
                        text-align: center;
                        color: $description-color;
                    }
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                        margin-bottom: 60px;
                    }
                    &:nth-of-type(1) {
                        > .image {
                            background: url(../../assets/images/exais/content3_img1.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(2) {
                        > .image {
                            background: url(../../assets/images/exais/content3_img2.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(3) {
                        > .image {
                            background: url(../../assets/images/exais/content3_img3.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(4) {
                        > .image {
                            background: url(../../assets/images/exais/content3_img4.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(5) {
                        > .image {
                            background: url(../../assets/images/exais/content3_img5.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                    &:nth-of-type(6) {
                        > .image {
                            background: url(../../assets/images/exais/content3_img6.png) no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                }
            }
        }

        > .content4 {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 200px;
            > .content4_inner {
                width: 1170px;
                margin-bottom: 80px;
                @include transition(width 2s);
                > .title {
                    font-size: 36px;
                    font-weight: 700;
                    margin-bottom: 15px;
                }
                > .content4_inner_inner {
                    width: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    align-items: center;
                    > div {
                        flex: 1;
                        height: 160px;
                        border-radius: 30px;
                        margin-right: 35px;
                        display: flex;
                        flex-flow: column wrap;
                        justify-content: center;
                        align-items: center;
                        background: linear-gradient(180deg, rgba(7, 7, 28, 0.5) 0%, rgba(155, 0, 121, 0.5) 100%);
                        &:last-of-type {
                            margin-right: 0;
                        }
                        > .value {
                            font-size: 24px;
                            font-weight: 700;
                            margin-bottom: 25px;
                        }
                        > .title {
                            font-size: 16px;
                            font-weight: 400;
                        }
                        &:nth-of-type(2n) {
                            background: linear-gradient(180deg, rgba(7, 7, 28, 0.5) 0%, rgba(85, 72, 186, 0.5) 100%);
                        }
                    }
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        > .content5 {
            width: 100%;
            margin-bottom: 200px;
            > .title {
                width: 100%;
                font-size: 36px;
                font-weight: 700;
                text-align: center;
                margin-bottom: 15px;
                white-space: normal;
            }
            > .description {
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: $description-color;
                margin-bottom: 40px;
            }
            > .content5_inner {
                height: 1450px;
                background: url(../../assets/images/exais/content4.png) no-repeat 50% 50%;
                background-size: contain;
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                > .content5_dot {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background: #FFF;
                    margin-top: 115px;
                    &::after {
                        position: absolute;
                        top: calc(50% - 1px);
                        right: 12px;
                        display: block;
                        content: "";
                        width: 135px;
                        height: 2px;
                        background: #FFF;
                        @include transition(width 2s);
                    }
                    > .content5_dot_box {
                        width: 250px;
                        position: absolute;
                        top: 0;
                        right: 155px;
                        display: flex;
                        flex-flow: column wrap;
                        align-items: flex-end;
                        @include transition(right 2s);
                        > .date {
                            font-style: italic;
                            font-size: 24px;
                            font-weight: 700;
                            margin-bottom: 6px;
                        }
                        > .description {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 19px;
                            text-align: right;
                            color: #757A88;
                        }
                    }
                    &:nth-of-type(1) {
                        margin-top: 370px;
                    }
                    &:nth-of-type(2n) {
                        &::after {
                            left: 12px;
                        }
                        > .content5_dot_box {
                            top: 0;
                            left: 155px;
                            position: absolute;
                            align-items: flex-start;
                            @include transition(left 2s);
                            > .description {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    
        > .content6 {
            > div {
                > .image {
                    flex: 1;
                    max-width: 570px;
                    height: 532px;
                    background: url(../../assets/images/exais/content5_img1.png) no-repeat 50% 50%;
                    background-size: contain;
                }
                > .content_box_inner {
                    > .normal_button {
                        position: inherit;
                        margin-top: 25px;
                    }
                }
                &:nth-of-type(2) {
                    flex-flow: row wrap;
                    > .image {
                        background: url(../../assets/images/exais/content5_img2.png) no-repeat 50% 50%;
                        background-size: contain;
                    }
                }
            }
        }
    }

    
}

@media (max-width: 1150px) {
    .exais_container {
        > .content1 {
            height: 415px;
        }

        > .container_inner {
            > .content2,
            > .content6 {
                margin-bottom: 160px;
                > div {
                    width: 680px;
                    > .image {
                        max-width: 330px;
                        height: 285px;
                    }
                    > .content_box_inner {
                        max-width: 330px;
                        > .title {
                            font-size: 36px;
                            margin-bottom: 15px;
                        }
                        > .description {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                    &:nth-of-type(2) {
                        margin-bottom: 0;
                        > .image {
                            height: 225px;
                        }
                    }
                }
            }

            > .content3 {
                margin-bottom: 200px;
                > .content3_box {
                    width: 680px;
                    > .content3_box_innerbox {
                        margin: 0;
                    }
                }
            }

            > .content4 {
                margin-bottom: 160px;
                > .content4_inner {
                    width: 680px;
                    > .content4_inner_inner {
                        justify-content: flex-start;
                        > div {
                            min-width: 200px;
                            max-width: 200px;
                            margin-right: 40px;
                            &:nth-of-type(1),
                            &:nth-of-type(2),
                            &:nth-of-type(3) {
                                margin-bottom: 55px;
                            }
                            &:nth-of-type(3) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            > .content5 {
                > .content5_inner {
                    > .content5_dot {
                        &::after {
                            width: 80px;
                        }
                        > .content5_dot_box {
                            right: 100px;
                        }
                        &:nth-of-type(2n) {
                            > .content5_dot_box {
                                left: 100px;
                            }
                        }
                    }
                }
            }

            > .content6 {
                > div {
                    flex-flow: row wrap;
                    > .image {
                        max-width: 330px;
                        height: 230px;
                        margin-right: 15px;
                    }
                }
            }
        }

        
    }
}

@media (max-width: 680px) {
    .exais_container {
        > .content1 {
            height: 261px;
        }

        > .container_inner {
            > .content2,
            > .content6 {
                margin-bottom: 120px;
                > div {
                    width: 310px;
                    flex-flow: column wrap !important;
                    > .image {
                        min-width: 310px;
                        max-width: 330px;
                        flex: inherit;
                        margin-bottom: 25px;
                    }
                    > .content_box_inner {
                        > .title {
                            font-size: 26px;
                        }
                        > .description {
                            font-size: 14px;
                        }
                    }
                }
            }
        
            > .content3 {
                margin-bottom: 120px;
                > .title {
                    font-size: 36px;
                }
                > .description {
                    font-size: 14px;
                    line-height: 21px;
                }
                > .content3_box {
                    width: 310px;
                    > .content3_box_innerbox {
                        margin-bottom: 30px !important;
                    }
                }
            }
        
            > .content4 {
                margin-bottom: 120px;
                > .content4_inner {
                    width: 310px;
                    > .title {
                        font-size: 32px;
                    }
                    > .content4_inner_inner {
                        > div {
                            min-width: 140px;
                            max-width: 140px;
                            height: 160px;
                            border-radius: 30px;
                            margin-right: 0;
                            > .value {
                                font-size: 20px;
                            }
                            > .title {
                                font-size: 14px;
                            }
                            &:nth-of-type(1) {
                                min-width: 310px;
                                max-width: 310px;
                                > .value {
                                    font-size: 24px;
                                }
                                > .title {
                                    font-size: 16px;
                                }
                            }
                            &:nth-of-type(1),
                            &:nth-of-type(2),
                            &:nth-of-type(3) {
                                margin-bottom: 25px;
                            }
                            &:nth-of-type(3),
                            &:last-of-type {
                                margin-right: 0;
                            }
                            &:nth-of-type(2),
                            &:nth-of-type(3) {
                                background: linear-gradient(180deg, rgba(7, 7, 28, 0.5) 0%, rgba(85, 72, 186, 0.5) 100%);
                            }
                            &:nth-of-type(4) {
                                background: linear-gradient(180deg, rgba(7, 7, 28, 0.5) 0%, rgba(155, 0, 121, 0.5) 100%);
                            }
                            &:nth-of-type(2),
                            &:nth-of-type(4) {
                                margin-right: 30px;
                            }
                        }
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        
            > .content5 {
                margin-bottom: 120px;
                padding: 0 25px;
                > .title {
                    white-space: pre;
                    font-size: 32px;
                }
                > .description {
                    white-space: normal;
                }
                > .content5_inner {
                    > .content5_dot {
                        &::after {
                            width: 36px;
                        }
                        > .content5_dot_box {
                            width: 115px;
                            right: 55px;
                            > p {
                                white-space: normal;
                            }
                        }
                        &:nth-of-type(2n) {
                            > .content5_dot_box {
                                left: 55px;
                            }
                        }
                    }
                }
            }
        
            > .content6 {
                > div {
                    > .content_box_inner {
                        > .normal_button {
                            position: inherit;
                            margin-top: 25px;
                        }
                    }
                }
            }
        }
    }
}
