//  walletPassword.scss

.wallet_box {
  &.password {
    padding-top: 0;
    background: #F4F4F4;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    > .content1 {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      > .title {
        width: 100%;
        font-weight: 700;
        font-size: 30px;
        text-align: center;
        color: #1C1B1F;
        margin-top: 78px;
        margin-bottom: 12px;
      }
      > .description {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #1C1B1F;
        margin-bottom: 25px;
      }
      > .password_dot {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 20px;
          background: #D9D9D9;
          &:last-of-type {
            margin-right: 0;
          }
          &.on {
            background: #6E46C7;
          }
        }
      }
    }
    > .number_pad {
      width: 100%;
      height: 380px;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 20px;
      column-gap: 35px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        font-size: 30px;
        color: #1C1B1F;
        border-radius: 50%;
        background: #FFF;
        color: #000;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        &:nth-of-type(10),
        &:nth-of-type(12) {
          background: #D9D9D9;
          box-shadow: none;
          &::after {
            display: block;
            content: "";
            width: 27.5px;
            height: 27.5px;
            background: url(../../../assets/images/wallet/password_close.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &:nth-of-type(12) {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #6E46C7;
          &::after {
            width: 34px;
            height: 34px;
            background: url(../../../assets/images/wallet/password_arrow.png) no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }
    }
  }
}