// main.scss

@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

.main_container {
    width: 100%;
    > .main_box1 {
        width: 100%;
        height: 1050px;
        background: url(../../assets/images/main/main_bg.png) no-repeat 50% 100%;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 80px;
        margin-bottom: 130px;
        @include transition(height 2s);
        > .main_box1_inner {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            > p {
                &:first-of-type {
                    width: 100%;
                    font-size: 85px;
                    font-weight: 700;
                    line-height: 100px;
                    font-style: italic;
                    color: #FFF;
                    text-align: center;
                    @include transition(font-size 2s);
                }
                &:nth-of-type(2) {
                    width: 100%;
                    font-size: 36px;
                    font-weight: 400;
                    color: #FFF;
                    text-align: center;
                    line-height: 50px;
                    margin: 20px 0;
                    @include transition(font-size 2s);
                }
            }
            
        }
    }
    > .main_box2,
    > .main_box3 {
        width: 100%;
        margin-bottom: 120px;
        display: flex;
        align-items: center;
        justify-content: center ;
        > .main_box2_inner,
        > .main_box3_inner {
            width: 1170px;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;

            > .content_box {
                flex: 1;
                // width: 570px;
                margin-right: 100px;
                > .title {
                    font-size: 48px;
                    font-weight: 700;
                    line-height: 48px;
                    color: #FFF;
                    font-style: italic;
                    @include transition(font-size 2s);
                }
                > .description {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    color: #767676;
                    margin: 25px 0;
                    white-space: normal;
                    @include transition(font-size 2s);
                }
            }   

            > .image {
                flex: 1;
                // width: 420px;
                height: 390px;
                background: url(../../assets/images/main/content1_img1.png) no-repeat 50% 50%;
                background-size: contain;
            } 
        }
        > .main_box3_inner {
            > .content_box {
                margin-right: 0;
                margin-left: 100px;
            }
            > .image {
                height: 390px;
                background: url(../../assets/images/main/content1_img2.png) no-repeat 50% 50%;
                background-size: contain;
            } 
        }
    }
}

.arrow_button {
    padding: 16px 32px;
    border-radius: 100px;
    border: 2px solid #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    > p {
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
        color: #FFF;
        margin-right: 15px;
        margin-top: 2px;
    }
    > .button_arrow {
        width: 12px;
        height: 20px;
        background: url(../../assets/images/button_img_w.png) no-repeat 50% 50%;
        background-size: contain;
    }
    &:hover {
        background: #FFF;
        > p {
            color: #000;
        }
        > .button_arrow {
            background: url(../../assets/images/button_img_b.png) no-repeat 50% 50%;
            background-size: contain;
        }
    }
}

@media (max-width: 1170px) {
    .main_container {
        > .main_box1 {
            height: 700px;
            padding-bottom: 50px;
            margin-bottom: 80px;
            > .main_box1_inner {
                > p {
                    &:first-of-type {
                        font-size: 70px;
                        line-height: 84px;
                    }
                    &:nth-of-type(2) {
                        font-size: 28px;
                        line-height: 42px;
                    }
                }

            }
        }
        > .main_box2,
        > .main_box3 {
            margin-bottom: 80px;
            > .main_box2_inner,
            > .main_box3_inner {
                width: 930px;

                > .content_box {
                    // width: 450px;
                    margin-right: 90px;
                    > .title {
                        font-size: 36px;
                        line-height: 43px;
                    }
                    > .description {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }   
                > .image {
                    // width: 345px;
                    height: 320px;
                } 
            }
            > .main_box3_inner {
                > .content_box {
                    margin-right: 0;
                    margin-left: 90px;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .main_container {
        > .main_box1 {
            height: 340px;
            padding-bottom: 0;
            padding-top: 40px;
            margin-bottom: 120px;
            > .main_box1_inner {
                > p {
                    &:first-of-type {
                        font-size: 32px;
                        line-height: 40px;
                    }
                    &:nth-of-type(2) {
                        font-size: 16px;
                        line-height: 28px;
                        margin: 10px 0;
                    }
                }

            }
        }
    }

    .arrow_button {
        padding: 10px 25px;
        > p {
            font-size: 16px;
            line-height: 16px;
        }
    }
}

@media (max-width: 768px) {
    .main_container {
        > .main_box1 {
            height: 340px;
            padding-bottom: 0;
            padding-top: 40px;
            margin-bottom: 45px;
            > .main_box1_inner {
                > p {
                    &:first-of-type {
                        font-size: 32px;
                        line-height: 40px;
                    }
                    &:nth-of-type(2) {
                        font-size: 16px;
                        line-height: 28px;
                        margin: 10px 0;
                    }
                }

            }
        }
        > .main_box2,
        > .main_box3 {
            padding: 0 25px;
            box-sizing: border-box;
            > .main_box2_inner,
            > .main_box3_inner {
                flex-flow: column wrap;
                > .image {
                    flex: inherit;
                    width: 100%;
                    max-width: 500px;
                    height: 400px;
                    margin-bottom: 25px;
                }
                > .content_box {
                    flex: inherit;
                    width: 100%;
                    max-width: 500px;
                    margin-right: 0;
                    margin-left: 0;
                    > .title {
                        font-size: 25px;
                        font-weight: 700;
                        line-height: 30px;
                    }
                    > .description {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        margin: 15px 0;
                    }
                }
            }
            > .main_box2_inner {
                flex-flow: column-reverse wrap;
            }
        }
        > .main_box2 {
            margin-bottom: 120px;
        }
    }

    .arrow_button {
        padding: 10px 25px;
        > p {
            font-size: 16px;
            line-height: 16px;
        }
    }
}