//  walletModal.scss

.wallet_modal_dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 0 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    > .wallet_modal_box {
        position: relative;
        width: 100%;
        background: #FFF;
        padding: 56px 30px 20px 30px;
        border-radius: 10px;
        box-sizing: border-box;
        > .close_button {
            position: absolute;
            top: 20px;
            right: 30px;
            width: 12px;
            height: 12px;
            background: url(../../../assets/images/wallet/wallet_modal_close.png) no-repeat 50% 50%;
            background-size: contain;
            cursor: pointer;
        }
        > .description {
            font-weight: 400;
            font-size: 15px;
            line-height: 120%;
            color: #152536;
            margin-bottom: 40px;
            > .highlight {
                display: block;
                font-weight: 700;
                margin-top: 18px;
                color: #152536;
            }
        }
        > .bottom_buttons {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            > .button {
                font-weight: 400;
                font-size: 15px;
                color: #97999E;
                cursor: pointer;
                &:last-of-type {
                    font-weight: 600;
                    font-size: 15px;
                    color: #6E46C7;
                    margin-left: 30px;
                }
            }
        }
    }
}