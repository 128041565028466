// ecosystem.scss

@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}


$description-color : #767676;

.ecosystem_container {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  >.content1 {
    position: relative;
    width: calc(100% - 40px);
    height: 700px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    margin: 0 20px;
    margin-bottom: 280px;
    @include transition(height 2s);

    &::after {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 661px;
      box-sizing: border-box;
      background: url(../../assets/images/ecosystem/content1_bg.png) no-repeat 50% 100%;
      background-size: cover;
      @include transition(height 2s);
    }

    >.content_box {
      z-index: 10;
      position: relative;
      width: 1170px;
      display: flex;
      flex-flow: row-reverse nowrap;

      >.image {
        position: relative;
        flex: 1;
        margin-right: 50px;

        &::after {
          position: absolute;
          top: calc(50% - 350px);
          left: calc(50% - 350px);
          display: block;
          content: "";
          width: 942px;
          height: 561px;
          background: url(../../assets/images/ecosystem/content1_img1.png) no-repeat 50% 50%;
          background-size: contain;
          @include transition(width, height, top, left 2s);
        }
      }

      >.content_box_inner {
        display: flex;
        flex: 1;
        flex-flow: column wrap;
        align-items: flex-start;
        justify-content: center;

        >p {
          white-space: normal;
        }

        >.sub-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          color: #6E46C7; 
        }

        >.title {
          font-size: 48px;
          font-weight: 700;
          font-style: italic;
          margin-bottom: 25px;
          @include transition(font-size 2s);
        }

        >.description {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          margin-bottom: 25px;
          color: #767676;
          @include transition(font-size 2s);
        }

        >.normal_button {
          position: inherit;
        }
      }
    }
  }

  >.content2 {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    >div {
      width: 1170px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-bottom: 80px;

      >.image {
        flex: 1;
        max-width: 570px;
        height: 395px;
        background: url(../../assets/images/ecosystem/content2_img1.png) no-repeat 50% 50%;
        background-size: contain;
        @include transition(height 2s);
      }

      >.content_box_inner {
        flex: 1;
        max-width: 570px;
        height: auto;
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
        // justify-content: flex-start;
        justify-content: center;
        @include transition(max-width 2s);

        >.title {
          width: 100%;
          font-size: 48px;
          font-weight: 700;
          font-style: italic;
          white-space: normal;
          color: #FFF;
          margin-bottom: 26px;
          @include transition(font-size 2s);
        }

        >.description {
          width: 100%;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          color: $description-color;
          white-space: normal;
          margin-bottom: 26px;
          @include transition(font-size 2s);
        }

        >.normal_button {
          position: inherit;
        }
      }

      &:nth-of-type(2) {
        flex-flow: row-reverse wrap;

        >.image {
          background: url(../../assets/images/ecosystem/content2_img2.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }

      &:nth-of-type(3) {
        >.image {
          background: url(../../assets/images/ecosystem/content2_img3.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .ecosystem_container {
    >.content1 {
      width: 100%;
      height: 570px;
      margin: 0;
      margin-bottom: 80px;

      &::after {
        background: url(../../assets/images/ecosystem/content1_bg_t.png) no-repeat 50% 100%;
        background-size: cover;
        height: 400px;
      }

      >.content_box {
        width: 690px;

        >.image {
          &::after {
            top: calc(50% - 320px);
            left: calc(50% - 300px);
            width: 448px;
            height: 267px;
          }
        }

        >.content_box_inner {
          flex: 6;

          >.title {
            font-size: 36px;
            margin-bottom: 15px;
          }

          >.description {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 15px;
          }
        }
      }
    }

    >.content2 {
      >div {
        width: 690px;
        >.image {
          max-width: 330px;
          height: 228px;
          margin-right: 30px;
        }

        >.content_box_inner {
          max-width: 330px;

          >.title {
            font-size: 36px;
            margin-bottom: 15px;
          }

          >.description {
            font-size: 16px;
            line-height: 24px;
          }

          >.normal_button {
            position: inherit;
          }
        }

        &:nth-of-type(2) {
          >.image {
            margin-right: 0;
            margin-left: 30px;
          }
        }
        &:nth-of-type(3) {
          margin-bottom: 0;
          >.content_box_inner >.title {
            margin-bottom: 24px;
          }
          >.image {
            margin-top: 26px;
          }
        }
      }
    }
  }
}

@media (max-width: 690px) {
  .ecosystem_container {
    >.content1 {
      width: 100%;
      height: 600px;
      margin: 0;
      margin-bottom: 80px;
      padding-top: 72px;

      &::after {
        background: url(../../assets/images/ecosystem/content1_bg_m.png) no-repeat 50% 100%;
        background-size: cover;
        top: 72px;
        height: 270px;
      }

      >.content_box {
        width: 310px;
        flex-flow: column wrap;

        >.image {
          flex: inherit;
          width: 310px;
          height: 185px;
          margin-right: 0;
          margin-bottom: 35px;

          &::after {
            top: 0;
            left: 0;
            width: 310px;
            height: 185px;
          }
        }

        >.content_box_inner {
          flex: inherit;

          >.title {
            font-size: 26px;
            margin-bottom: 15px;
          }

          >.description {
            width: 100%;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 15px;
            white-space: normal;
          }
        }
      }
    }

    >.content2 {
      >div {
        width: 310px;
        flex-flow: column wrap;

        >.image {
          flex: inherit;
          width: 310px;
          max-width: inherit;
          height: 214px;
          margin-right: 0;
          margin-bottom: 24px;
        }

        >.content_box_inner {
          max-width: 330px;

          >.title {
            font-size: 26px;
            margin-bottom: 15px;
          }

          >.description {
            font-size: 14px;
            line-height: 24px;
          }

          >.normal_button {
            position: inherit;
          }
        }

        &:nth-of-type(2) {
          >.image {
            margin-right: 0;
            margin-left: 0;
          }
        }

        &:nth-of-type(3) {
          margin-bottom: 0;
        }
      }
    }
  }
}