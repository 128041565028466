// walletTerms.scss

.wallet_box {
  
  // for walletTerms page
  &.terms {
    > .terms-header{
      width: 100%;
      // height: 419px;
      margin-top: 194px;
      // margin-bottom: 190px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      > .terms-title{
        font-size: 30px;
        font-weight: 700;
        color: #1C1B1F; 
        text-align: center;
      }
    }
    > .wallet-terms-body {
      width: 100%;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      overflow-y: scroll;
    
      > .wallet-checkbox{
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
      
        > .check-items {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-decoration-line: underline;
          /* Heading/Title Color */
          color: #152536;
          > .checkbox,
          .checkbox.check {
            position: relative;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background: #EEEEEE;
            border: 1px solid #D9D9D9;
            border-radius: 2px;
            box-sizing: border-box;
            &::after {
              position: absolute;
              top: calc(50% - 8px);
              left: calc(50% - 8px);
              content: '';
              width: 16px;
              height: 16px;
            }
          }
          .checkbox {
            cursor: pointer;
            &::after {
              background: url(../../../assets/images/wallet/wallet_login_checkbox_icon.png) no-repeat 50% 50%;
              background-size: contain;
            }
            &.check {
              background: #6E46C7;
              border: none;
              &::after {
                background: url(../../../assets/images/wallet/wallet_login_checkbox_icon_w.png) no-repeat 50% 50%;
                background-size: contain;
              }
            }
          }
          > p {
            font-weight: 400;
            font-size: 15px;
            line-height: 12px;
            color: #1C1B1F; 
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  // for walletConditions page
  &.conditions {
    justify-content: flex-start;
    justify-items: flex-start;
    flex-wrap: nowrap;
    > .wallet_header {
      width: 100%;
    }
    > .wallet-condition-body {
      width: 100%;
      height: 667px;
      padding: 20px 32px;
      overflow-y: scroll;
      p, span {
        white-space: pre-wrap;
        color: #000;
      }
      > .terms_title {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 22px;
      }
      > .terms_des,
      > .terms_des_num {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 22px;
      }
      > .terms_des_num {
        text-indent: -18px;
        padding-left: 18px;
      }
      .depth1,
      .depth2,
      .depth3,
      .depth4 {
        display: block;
        text-indent: 0;
      }
      .depth1 {
        margin-top: 22px;
        margin-left: 18px;
      }
      .depth2 {
        margin-left: 36px;
      }
      .depth3 {
        margin-top: 22px;
        margin-left: 54px;
      }
      .depth4 {
        margin-left: 72px;
      }
    }
  }

}